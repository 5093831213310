export const BASE_URL: string = process.env['NG_APP_API_BASE_URL'] ?? 'localhost';
export const API_URL: string = '/api/v1';

export const LOCAL_STORAGE_KEYS = {
  USER: '@CURRENT_USER',
  AUTHORIZED: '@AUTHORIZED',
  TARIFFS: '@TARIFFS',
  SUBSCRIPTION: '@SUBSCRIPTION',
  FAVORITES: '@FAVORITES',
  TOKENS: '@TOKENS',
};
