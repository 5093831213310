import { IOption } from 'src/app/shared/interfaces/chart-options.interface';

export enum EActiveTypes {
  ACTIVES,
  SIGNALS,
  NEWS,
}

export const ActivesTypesAliases: Record<EActiveTypes, string> = {
  [EActiveTypes.ACTIVES]: 'Активы',
  [EActiveTypes.SIGNALS]: 'Сигналы',
  [EActiveTypes.NEWS]: 'Лента новостей',
};

export const activesTypeOptions: IOption<EActiveTypes>[] = [
  {
    label: ActivesTypesAliases[EActiveTypes.ACTIVES],
    value: EActiveTypes.ACTIVES,
  },
  {
    label: ActivesTypesAliases[EActiveTypes.SIGNALS],
    value: EActiveTypes.SIGNALS,
  },
  {
    label: ActivesTypesAliases[EActiveTypes.NEWS],
    value: EActiveTypes.NEWS,
  },
];
