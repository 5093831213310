import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChartFilters } from 'src/app/modules/chart/models/chart-filters';
import { ChartComponent } from 'src/app/modules/chart/components/chart/chart.component';
import { cloneDeep } from 'lodash';
import {
  chartPeriods,
  chartIntervals,
  EChartIntervals,
  EChartPeriods,
  EChartTypes,
} from '../../modules/chart/models/chart-types';
import { getCurrentOption } from '../../shared/utils/options';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private activeInstance: ChartComponent | null = null;
  private state: ChartFilters;

  updateState$ = new Subject<ChartFilters>();

  get currentState(): ChartFilters {
    return this.state;
  }

  subscribe(instance: ChartComponent) {
    this.activeInstance = instance;
  }

  clearSubscriptions(): void {
    this.activeInstance = null;
  }

  notifyActiveComponent(state?: ChartFilters): void {
    if (state) {
      this.state = cloneDeep(state);
    }

    this.activeInstance?.loadData(this.currentState);
  }

  getIntervalOptions(filterState: ChartFilters) {
    return chartIntervals.filter(item => {
      const showOnline =
        filterState.isOnline
        && filterState.type !== EChartTypes.FUTURES
        && filterState.period !== EChartPeriods.ALLTIME;

      return !item.isOnline || showOnline;
    });
  }

  getPeriodOptions(filterState: ChartFilters) {
    return chartPeriods.filter(item => {
      const showOnline = filterState.isOnline && filterState.type !== EChartTypes.FUTURES;

      return !item.isOnline || showOnline;
    });
  }

  updateIntervalValue(filterState: ChartFilters): boolean {
    const currentInterval = getCurrentOption<EChartIntervals>(
        this.getIntervalOptions(filterState),
        filterState.interval
    );

    if (filterState.interval !== currentInterval.value) {
      filterState.interval = currentInterval.value;
      return true;
    }

    return false;
  }

  updatePeriodValue(filterState: ChartFilters): boolean {
    const currentPeriod = getCurrentOption<EChartPeriods>(
        this.getPeriodOptions(filterState),
        filterState.period
    );

    if (filterState.period !== currentPeriod.value) {
      filterState.period = currentPeriod.value;
      return true;
    }

    return false;
  }
}
