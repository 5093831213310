import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, BASE_URL } from 'src/constants';
import { Observable } from 'rxjs';
import {
  IGetAssetPositionResponseDTO,
  IGetAssetPriceResponseDTO,
  IGetAssetRsiResponseDTO,
} from '../dto/reports';
import { IDateFilter } from '../models/date-filter';
import { getStringedDate } from '../../../shared/utils/stringed-date';

@Injectable({
  providedIn: 'root',
})
export class ChartRepository {
  constructor(private readonly _http: HttpClient) {}

  public getIndividualPosition(
    assetId: number | string,
    dateFilter: IDateFilter,
    iz_fiz: boolean = true
  ): Observable<IGetAssetPositionResponseDTO> {
    return this.getReport<IGetAssetPositionResponseDTO>(
      'individual',
      assetId,
      dateFilter,
      iz_fiz
    );
  }

  public getLegalPosition(
    assetId: number | string,
    dateFilter: IDateFilter,
    iz_fiz: boolean = true
  ): Observable<IGetAssetPositionResponseDTO> {
    return this.getReport<IGetAssetPositionResponseDTO>(
      'legal',
      assetId,
      dateFilter,
      iz_fiz
    );
  }

  public getPrice(
    assetId: number | string,
    dateFilter: IDateFilter,
    iz_fiz: boolean = false
  ): Observable<IGetAssetPriceResponseDTO> {
    return this.getReport<IGetAssetPriceResponseDTO>(
      'price',
      assetId,
      dateFilter,
      iz_fiz
    );
  }

  public getRsi(
    assetId: number | string,
    dateFilter: IDateFilter,
    iz_fiz: boolean = true
  ): Observable<IGetAssetRsiResponseDTO> {
    return this.getReport<IGetAssetRsiResponseDTO>(
      'rsi',
      assetId,
      dateFilter,
      iz_fiz
    );
  }

  private getReport<T>(
    type: 'individual' | 'legal' | 'price' | 'rsi',
    assetId: number | string,
    dateFilter: IDateFilter,
    iz_fiz: boolean = true
  ): Observable<T> {
    return this._http.get<T>(`${BASE_URL}${API_URL}/assets/${assetId}/${type}`, {
      params: {
        from: getStringedDate(dateFilter.range.from),
        to: getStringedDate(dateFilter.range.to),
        interval: dateFilter.interval,
        type: dateFilter.type,
        iz_fiz,
      },
      withCredentials: true,
    });
  }
}
