<ng-container *ngIf="descriptionService.currentChartType$ | async; let type">
  <p class="analytics__description" *ngIf="type == EChartTypes.LEGAL">
    <span class="analytics__mark analytics__mark--orange"></span
    ><b>График шорт позиций</b> – отображает объём шорт (продажных) позиций на
    срочном рынке. <br /><br />
    <span class="analytics__mark analytics__mark--green"></span
    ><b>График лонг позиций</b> – показывает объём лонг (покупных) позиций на
    срочном рынке. <br /><br />
    <span class="analytics__mark analytics__mark--yellow"></span
    ><b>График чистых позиций</b> – вычисляет разницу между лонг и шорт
    позициями.
  </p>

  <p class="analytics__description" *ngIf="type == EChartTypes.INDIVIDUAL">
    <span class="analytics__mark analytics__mark--pink"></span
    ><b>График продавцов</b> – отображает количество продавцов актива на срочном
    рынке. <br /><br />
    <span class="analytics__mark analytics__mark--blue"></span
    ><b>График покупателей</b> – показывает количество покупателей актива на
    срочном рынке. <br /><br />
    <span class="analytics__mark analytics__mark--purple"></span
    ><b>График чистых позиций</b> – вычисляет разницу между количеством
    продавцов и покупателей актива.
  </p>

  <p class="analytics__description" *ngIf="type == EChartTypes.RSI">
    Сигналы показывают уровень пессимизма и оптимизма участников рынка в активе.
    Рассчитываются на основе индикатора RSI, наложенного на чистые позиции
    участников рынка.
    <br /><br />
    <span class="analytics__mark analytics__mark--green"></span>Наблюдается
    оптимизм. Сильное сокращение продаж и увеличение покупок. <br /><br />
    <span class="analytics__mark analytics__mark--red"></span>Наблюдается
    пессимизм. Сильное сокращение покупок и увеличение продаж. <br /><br />
    <span class="analytics__mark analytics__mark--gray"></span>Нейтральное
    настроение участников рынка.
  </p>

  <p class="analytics__description" *ngIf="type == EChartTypes.FUTURES">
    Итоги торгов по фьючерсному контракту с ближайший датой исполнения за
    период. На цене цветом отображены сигналы.
    <br /><br />
    <span class="analytics__mark analytics__mark--green"></span>Наблюдается
    оптимизм. Сильное сокращение продаж и увеличение покупок. <br /><br />
    <span class="analytics__mark analytics__mark--red"></span>Наблюдается
    пессимизм. Сильное сокращение покупок и увеличение продаж. <br /><br />
    <span class="analytics__mark analytics__mark--gray"></span>Нейтральное
    настроение участников рынка.
  </p>
</ng-container>
