<div
  class="mb-6 flex justify-between items-center max-small:flex-col"
  [ngClass]="{ 'max-laptop:hidden': router.url.includes('/chart') }"
>
  <button
    *ngIf="!router.url.includes('/chart')"
    class="button--white small:w-[220px] laptop:ml-6 max-small:mt-5 w-full"
    routerLink="/assets"
  >
    Все активы
  </button>
</div>
<div class="relative">
  <div
    #skeleton
    [@skeletonAnimation]="chartService.isFullscreen ? 'wide' : 'small'"
    class="bg-gray3 rounded-lg top-0 right-0 bottom-0 left-0 z-[-1] absolute opacity-50 flex items-center justify-center"
  >
    <span>Полноэкранный режим</span>
  </div>

  <div
    #analytics
    class="analytics"
    [@fullscreenAnimation]="chartService.isFullscreen ? 'wide' : 'small'"
  >
    <div class="flex justify-between mb-6 filters-container">
      <app-chart-filters />
      <div
        class="min-w-[334px] flex flex-wrap justify-end max-laptop:basis-0 gap-4 content-baseline"
      >
        <label
          (click)="toggleFullscreen()"
          class="analytics__fullscreen min-w-[172px] max-h-[40px]"
        >
          <span class="analytics__fullscreen-label">
            {{ chartService.isFullscreen ? 'Свернуть' : 'На весь экран' }}
          </span>
          <span class="analytics__fullscreen-icon">
            <icon-fullscreen *ngIf="!chartService.isFullscreen" />
            <icon-smallscreen *ngIf="chartService.isFullscreen" />
          </span>
        </label>

        <app-view-select
          class="analytics__fullscreen !ml-0 min-w-[90px] max-h-[40px] !self-auto"
          [current]="getCurrentOption(viewOptions, chartView)"
          (valueChanged)="
            setChartView($event.value, $event.value === ChartView.TRIPLE)
          "
        />

        <app-icon-select
          (valueChanged)="addChart($event.value)"
        ></app-icon-select>
      </div>
    </div>

    <div
      class="grid responsive-size"
      [class.gap-4]="chartView !== ChartView.SINGLE"
      [class.grid-rows-2]="chartView === ChartView.FOUR"
      [class.grid-cols-2]="
        chartView === ChartView.DOUBLE_VER || chartView === ChartView.FOUR
      "
      [class.grid-cols-[65%_35%]]="chartView === ChartView.TRIPLE"
    >
      <div
        class="cell w-full"
        [class.hidden]="!componentsArray[0]"
        [ngStyle]="componentsArray[0]?.cssStyles"
        (click)="makeComponentActive(0)"
      >
        <ng-template #first></ng-template>
        <label
          *ngIf="chartView !== ChartView.SINGLE"
          [ngClass]="checkIfComponentIsNews(0) ? 'mt-4' : 'mt-2'"
          class="close-btn px-2 ml-4 border rounded-lg h-[40px] w-[40px] border-gray3 gray3__opacity absolute z-[10] top-0 right-0 mr-4 bg-gray1 cursor-pointer"
          (click)="$event.stopPropagation(); closeChart(0)"
        >
          <icon-close />
        </label>
      </div>
      <div
        class="cell"
        [class.hidden]="!componentsArray[1]"
        [ngStyle]="componentsArray[1]?.cssStyles"
        (click)="makeComponentActive(1)"
      >
        <ng-template #second></ng-template>
        <label
          *ngIf="componentsArray[1]"
          [ngClass]="checkIfComponentIsNews(1) ? 'mt-4' : 'mt-2'"
          class="close-btn px-2 ml-4 border rounded-lg h-[40px] w-[40px] border-gray3 gray3__opacity absolute z-[10] top-0 right-0 mr-4 bg-gray1 cursor-pointer"
          (click)="$event.stopPropagation(); closeChart(1)"
        >
          <icon-close />
        </label>
      </div>

      <div
        class="cell"
        [class.hidden]="!componentsArray[2]"
        [ngStyle]="componentsArray[2]?.cssStyles"
        (click)="makeComponentActive(2)"
      >
        <ng-template #third></ng-template>
        <label
          *ngIf="componentsArray[2]"
          [ngClass]="checkIfComponentIsNews(2) ? 'mt-4' : 'mt-2'"
          class="close-btn px-2 ml-4 border rounded-lg h-[40px] w-[40px] border-gray3 gray3__opacity absolute z-[10] top-0 right-0 mr-4 bg-gray1 cursor-pointer"
          (click)="$event.stopPropagation(); closeChart(2)"
        >
          <icon-close />
        </label>
      </div>
      <div
        class="cell"
        [class.hidden]="!componentsArray[3]"
        [ngStyle]="componentsArray[3]?.cssStyles"
        (click)="makeComponentActive(3)"
      >
        <ng-template #fourth></ng-template>
        <label
          *ngIf="componentsArray[3]"
          [ngClass]="checkIfComponentIsNews(3) ? 'mt-4' : 'mt-2'"
          class="close-btn px-2 ml-4 border rounded-lg h-[40px] w-[40px] border-gray3 gray3__opacity absolute z-[10] top-0 right-0 mr-4 bg-gray1 cursor-pointer"
          (click)="$event.stopPropagation(); closeChart(3)"
        >
          <icon-close />
        </label>
      </div>
    </div>

    <div class="my-6 border border-[1px] border-gray3"></div>

    <app-chart-description />
  </div>
</div>
