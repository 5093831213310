import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsRepository } from './repository/news';
import { NewsService } from './news.service';

@NgModule({
  providers: [NewsRepository, NewsService],
  imports: [CommonModule],
})
export class NewsModule {}
