import { EChartPeriods, EChartIntervals } from '../models/chart-types';
import { EDateFilterIntervalType, IDateFilter } from '../models/date-filter';

export function convertIntervalToDimension(interval: EChartIntervals): number {
  switch (interval) {
    case EChartIntervals.FIVEMINUTES:
    case EChartIntervals.HOUR:
      return interval * 60 * 1000;
    default:
      return 0;
  }
}

export function convertIntervalAndPeriodToDateFilter(
  interval: EChartIntervals,
  period: EChartPeriods
): IDateFilter {
  const type = interval === EChartIntervals.DAY ? EDateFilterIntervalType.DAY : EDateFilterIntervalType.MINUTE;
  return {
    range: getDateRangeByInterval(period),
    type: type,
    interval: interval,
  };
}

function getDateRangeByInterval(period: EChartPeriods): {
  from: Date;
  to: Date;
} {
  const range = {
    from: new Date(),
    to: new Date(),
  };
  const current = new Date();

  switch (period) {
    case EChartPeriods.TODAY:
      range.from = new Date();
      range.to = new Date();
      break;
    case EChartPeriods.WEEK:
      const firstDayOfWeek = current.getDate() - 6;
      range.from = new Date(current.setDate(firstDayOfWeek));
      range.to = new Date();
      break;
    case EChartPeriods.MONTH:
      const firstDayOfMonth = current.getMonth() - 1;
      range.from = new Date(current.setMonth(firstDayOfMonth));
      range.to = new Date();
      break;
    case EChartPeriods.SIXMONTHS:
      const firstDayOfSixMonth = current.getMonth() - 6;
      range.from = new Date(current.setMonth(firstDayOfSixMonth));
      range.to = new Date();
      break;
    case EChartPeriods.YEAR:
      const firstDayOfYear = current.getFullYear() - 1;
      range.from = new Date(current.setFullYear(firstDayOfYear));
      range.to = new Date();
      break;
    case EChartPeriods.TWOYEARS:
      const firstDayOfTwoYears = current.getFullYear() - 2;
      range.from = new Date(current.setFullYear(firstDayOfTwoYears));
      range.to = new Date();
      break;
    case EChartPeriods.ALLTIME:
      const firstDayOfAllTime = current.getFullYear() - 20;
      range.from = new Date(current.setFullYear(firstDayOfAllTime));
      range.to = new Date();
      break;
  }

  return range;
}
