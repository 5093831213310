import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { defaultState } from '../chart-filters/default-state';
import { EChartTypes } from 'src/app/modules/chart/models/chart-types';

@Injectable({
  providedIn: 'root',
})
export class DescriptionService {
  currentChartType$ = new BehaviorSubject<EChartTypes>(defaultState.type);
}
