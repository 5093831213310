<div
  class="favorite"
  [ngClass]="{ 'favorite--checked': inFavorites() }"
  (click)="toggleFavorite($event)"
  [title]="
    inFavorites() ? 'Удалить актив из избранного' : 'Добавить актив в избранное'
  "
>
  <div class="favorite__shell">
    <svg
      *ngIf="!isLoading() && !inFavorites()"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        class="svg-fill"
        d="M22.0005 9.67002C21.9373 9.48711 21.8224 9.32645 21.6698 9.20753C21.5171 9.0886 21.3333 9.01652 21.1405 9.00002L15.4505 8.17002L12.9005 3.00002C12.8186 2.83095 12.6907 2.68837 12.5316 2.5886C12.3724 2.48883 12.1883 2.43591 12.0005 2.43591C11.8126 2.43591 11.6286 2.48883 11.4694 2.5886C11.3102 2.68837 11.1824 2.83095 11.1005 3.00002L8.55047 8.16002L2.86047 9.00002C2.67539 9.02633 2.50139 9.10399 2.35822 9.22418C2.21504 9.34438 2.10843 9.5023 2.05047 9.68002C1.99741 9.8537 1.99265 10.0385 2.03669 10.2147C2.08074 10.3909 2.17192 10.5517 2.30047 10.68L6.43047 14.68L5.43047 20.36C5.39009 20.5484 5.40502 20.7445 5.47345 20.9247C5.54188 21.1048 5.6609 21.2613 5.8162 21.3754C5.97149 21.4895 6.15644 21.5563 6.34879 21.5678C6.54115 21.5792 6.73272 21.5349 6.90047 21.44L12.0005 18.77L17.1005 21.44C17.2408 21.5192 17.3993 21.5606 17.5605 21.56C17.7723 21.5608 17.9789 21.4942 18.1505 21.37C18.3056 21.2589 18.4257 21.1057 18.4966 20.9285C18.5675 20.7513 18.5862 20.5575 18.5505 20.37L17.5505 14.69L21.6805 10.69C21.8248 10.5677 21.9316 10.4069 21.9882 10.2264C22.0448 10.0458 22.0491 9.8529 22.0005 9.67002ZM15.8505 13.67C15.7347 13.7824 15.6479 13.9212 15.5974 14.0744C15.5469 14.2276 15.5342 14.3908 15.5605 14.55L16.2805 18.75L12.5205 16.75C12.3743 16.6777 12.2135 16.6401 12.0505 16.6401C11.8874 16.6401 11.7266 16.6777 11.5805 16.75L7.82047 18.75L8.54047 14.55C8.5667 14.3908 8.55403 14.2276 8.50353 14.0744C8.45304 13.9212 8.36621 13.7824 8.25047 13.67L5.25047 10.67L9.46047 10.06C9.62246 10.0375 9.77646 9.97556 9.90896 9.87967C10.0415 9.78379 10.1484 9.65686 10.2205 9.51002L12.0005 5.70002L13.8805 9.52002C13.9525 9.66686 14.0595 9.79379 14.192 9.88968C14.3245 9.98556 14.4785 10.0475 14.6405 10.07L18.8505 10.68L15.8505 13.67Z"
        fill="#fff"
      />
    </svg>
    <svg
      *ngIf="!isLoading() && inFavorites()"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 3L14.2451 9.90983H21.5106L15.6327 14.1803L17.8779 21.0902L12 16.8197L6.12215 21.0902L8.36729 14.1803L2.48944 9.90983H9.75486L12 3Z"
        fill="#1BCD54"
      />
      <path
        d="M22 9.67015C21.9368 9.48723 21.822 9.32657 21.6693 9.20765C21.5167 9.08872 21.3328 9.01664 21.14 9.00014L15.45 8.17015L12.9 3.00015C12.8181 2.83107 12.6902 2.68849 12.5311 2.58872C12.3719 2.48895 12.1878 2.43604 12 2.43604C11.8121 2.43604 11.6281 2.48895 11.4689 2.58872C11.3097 2.68849 11.1819 2.83107 11.1 3.00015L8.54998 8.16014L2.85998 9.00014C2.6749 9.02645 2.5009 9.10411 2.35773 9.2243C2.21455 9.3445 2.10794 9.50242 2.04998 9.68015C1.99692 9.85382 1.99216 10.0387 2.03621 10.2148C2.08025 10.391 2.17144 10.5519 2.29998 10.6801L6.42998 14.6801L5.42998 20.3601C5.3896 20.5486 5.40453 20.7446 5.47296 20.9248C5.54139 21.1049 5.66041 21.2614 5.81571 21.3755C5.971 21.4896 6.15595 21.5564 6.34831 21.5679C6.54066 21.5794 6.73224 21.535 6.89998 21.4401L12 18.7701L17.1 21.4401C17.2403 21.5193 17.3988 21.5607 17.56 21.5601C17.7718 21.5609 17.9784 21.4944 18.15 21.3701C18.3051 21.259 18.4252 21.1058 18.4961 20.9286C18.567 20.7514 18.5857 20.5576 18.55 20.3701L17.55 14.6901L21.68 10.6901C21.8244 10.5678 21.9311 10.407 21.9877 10.2265C22.0444 10.0459 22.0486 9.85302 22 9.67015ZM15.85 13.6701C15.7342 13.7826 15.6474 13.9213 15.5969 14.0745C15.5464 14.2278 15.5337 14.3909 15.56 14.5501L16.28 18.7501L12.52 16.7501C12.3738 16.6779 12.213 16.6403 12.05 16.6403C11.8869 16.6403 11.7261 16.6779 11.58 16.7501L7.81998 18.7501L8.53998 14.5501C8.56622 14.3909 8.55354 14.2278 8.50305 14.0745C8.45255 13.9213 8.36572 13.7826 8.24998 13.6701L5.24998 10.6701L9.45998 10.0601C9.62198 10.0376 9.77598 9.97569 9.90848 9.8798C10.041 9.78391 10.1479 9.65698 10.22 9.51015L12 5.70015L13.88 9.52015C13.952 9.66698 14.059 9.79391 14.1915 9.8898C14.324 9.98569 14.478 10.0476 14.64 10.0701L18.85 10.6801L15.85 13.6701Z"
        fill="#1BCD54"
      />
    </svg>
    <svg
      *ngIf="isLoading()"
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 0 0"
      xml:space="preserve"
    >
      <path
        fill="#fff"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>

  <div
    class="favorite__info z-10 cursor-auto"
    (click)="$event.stopPropagation()"
  >
    <div
      *ngIf="userService.isAuthorized"
      class="p-4 w-[317px] rounded-lg border-[1px] border-gray4 bg-gray1 text-body-3"
    >
      Добавьте актив в избранное, чтобы получать уведомления об изменении цены.
      <br /><br />
      Все избранные активы будут отображаться в вашем
      <br /><br />
      <a
        routerLink="/profile/subscriptions"
        class="text-greenPrimary text-[1rem] font-bold hover:brightness-50 transition-all"
        >Личном кабинете</a
      >
    </div>
    <div
      *ngIf="!userService.isAuthorized"
      class="p-4 w-[317px] rounded-lg border-[1px] border-gray4 bg-gray1 text-body-3"
    >
      Подписка доступна только авторизованным пользователям
      <br /><br />
      <span
        (click)="showLoginWindow()"
        class="text-greenPrimary text-[1rem] font-bold hover:brightness-50 transition-all cursor-pointer"
      >
        Авторизоваться
      </span>
    </div>
  </div>
</div>
