import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ProfileSubscriptionsComponent } from './profile-subscriptions/profile-subscriptions.component';
import { ProfileTariffsComponent } from './profile-tariffs/profile-tariffs.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ProfileHomeComponent } from './profile-home/profile-home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthFormModule } from '../../features/auth-form/auth-form.module';
import { SubscriptionModule } from 'src/app/modules/subscription/subscription.module';
import { FavoritesModule } from 'src/app/modules/favorites/favorites.module';
import { TogglerComponent } from '../../ui/controls/toggler/toggler.component';
import { SearchBarComponent } from '../../ui/controls/search-bar/search-bar.component';
import { SelectComponent } from '../../ui/controls/select/select.component';
import { CheckboxComponent } from '../../ui/controls/checkbox/checkbox.component';
import { FavoritesTableComponent } from './profile-subscriptions/favorites-table/favorites-table.component';
import { LoaderDotsComponent } from '../../ui/loader-dots/loader-dots.component';
import { IconArrowDownComponent, IconArrowUpComponent } from '../../icons/icons.component';
import { ValueSignPipe } from 'src/app/pipes/value-sign.pipe';
import { UserModule } from 'src/app/modules/user/user.module';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileSubscriptionsComponent,
    ProfileTariffsComponent,
    ProfileHomeComponent,
    FavoritesTableComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    AuthFormModule,
    SubscriptionModule,
    FavoritesModule,
    TogglerComponent,
    SearchBarComponent,
    SelectComponent,
    CheckboxComponent,
    FavoritesModule,
    LoaderDotsComponent,
    ValueSignPipe,
    UserModule,

    // ------ Icons
    IconArrowDownComponent,
    IconArrowUpComponent,
  ],
})
export class ProfileScreenModule {}
