import { IOption } from 'src/app/shared/interfaces/chart-options.interface';

export enum ChartView {
  SINGLE = 'single',
  DOUBLE_HOR = 'horizontal',
  DOUBLE_VER = 'vertical',
  TRIPLE = 'triple',
  FOUR = 'four',
}

export enum EChartTypes {
  INDIVIDUAL = 'individual',
  LEGAL = 'legal',
  RSI = 'rsi',
  FUTURES = 'futures',
}

export enum EChartIntervals {
  FIVEMINUTES = 5,
  HOUR = 60,
  DAY = 1,
}

export enum EChartPeriods {
  TODAY,
  WEEK,
  MONTH,
  SIXMONTHS,
  YEAR,
  TWOYEARS,
  ALLTIME,
}

export const chartIntervalsAliases: Record<EChartIntervals, string> = {
  [EChartIntervals.FIVEMINUTES]: '5 мин.',
  [EChartIntervals.HOUR]: '1 час',
  [EChartIntervals.DAY]: '1 день',
};

export const chartPeriodAliases: Record<EChartPeriods, string> = {
  [EChartPeriods.TODAY]: 'Сегодня',
  [EChartPeriods.WEEK]: 'Неделя',
  [EChartPeriods.MONTH]: 'Месяц',
  [EChartPeriods.SIXMONTHS]: '6 месяцев',
  [EChartPeriods.YEAR]: '1 год',
  [EChartPeriods.TWOYEARS]: '2 года',
  [EChartPeriods.ALLTIME]: 'Все время',
};

export const ChartTypesAliases: Record<EChartTypes, string> = {
  [EChartTypes.INDIVIDUAL]: 'Покупатели-Продавцы',
  [EChartTypes.LEGAL]: 'Покупки-Продажи',
  [EChartTypes.RSI]: 'Сигналы',
  [EChartTypes.FUTURES]: 'Цена актива',
};

export const chartTypeOptions: IOption<EChartTypes>[] = [
  {
    label: ChartTypesAliases[EChartTypes.LEGAL],
    value: EChartTypes.LEGAL,
  },
  {
    label: ChartTypesAliases[EChartTypes.INDIVIDUAL],
    value: EChartTypes.INDIVIDUAL,
  },
  {
    label: ChartTypesAliases[EChartTypes.RSI],
    value: EChartTypes.RSI,
  },
  {
    label: ChartTypesAliases[EChartTypes.FUTURES],
    value: EChartTypes.FUTURES,
  },
];

export const chartIntervals: IOption<EChartIntervals>[] = [
  {
    label: chartIntervalsAliases[EChartIntervals.FIVEMINUTES],
    value: EChartIntervals.FIVEMINUTES,
    isOnline: true,
  },
  {
    label: chartIntervalsAliases[EChartIntervals.HOUR],
    value: EChartIntervals.HOUR,
    isOnline: true,
  },
  {
    label: chartIntervalsAliases[EChartIntervals.DAY],
    value: EChartIntervals.DAY,
    isOnline: false,
  },
];

export const chartPeriods: IOption<EChartPeriods>[] = [
  {
    label: chartPeriodAliases[EChartPeriods.TODAY],
    value: EChartPeriods.TODAY,
    isOnline: true,
  },
  {
    label: chartPeriodAliases[EChartPeriods.WEEK],
    value: EChartPeriods.WEEK,
    isOnline: false,
  },
  {
    label: chartPeriodAliases[EChartPeriods.MONTH],
    value: EChartPeriods.MONTH,
    isOnline: false,
  },
  {
    label: chartPeriodAliases[EChartPeriods.SIXMONTHS],
    value: EChartPeriods.SIXMONTHS,
    isOnline: false,
  },
  {
    label: chartPeriodAliases[EChartPeriods.YEAR],
    value: EChartPeriods.YEAR,
    isOnline: false,
  },
  {
    label: chartPeriodAliases[EChartPeriods.TWOYEARS],
    value: EChartPeriods.TWOYEARS,
    isOnline: false,
  },
  {
    label: chartPeriodAliases[EChartPeriods.ALLTIME],
    value: EChartPeriods.ALLTIME,
    isOnline: false,
  },
];

export const chartViewOptions: IOption<ChartView>[] = [
  {
    label: '',
    value: ChartView.SINGLE,
  },
  {
    label: '',
    value: ChartView.DOUBLE_HOR,
  },
  {
    label: '',
    value: ChartView.DOUBLE_VER,
  },
  {
    label: '',
    value: ChartView.TRIPLE,
  },
  {
    label: '',
    value: ChartView.FOUR,
  },
];
