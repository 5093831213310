import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { ChartView } from './models/chart-types';
import { ChartComponent } from './components/chart/chart.component';
import { NewsComponent } from 'src/app/components/features/news/news.component';

export interface ComponentObject {
  component: ComponentRef<ChartComponent | NewsComponent>;
  cssStyles?: any;
}

@Injectable({ providedIn: 'root' })
export class ChartViewService {
  constructor(private resolver: ComponentFactoryResolver) {}

  view = ChartView.SINGLE;
  componentsArray: (ComponentObject | undefined)[] = [];
  indexToRemove: number | null = null;

  private firstContainer: ViewContainerRef;
  private secondContainer: ViewContainerRef;
  private thirdContainer: ViewContainerRef;
  private fourthContainer: ViewContainerRef;

  drawComponent(drawNews = false): void {
    if (this.indexToRemove !== null) {
      this.clearContainer();
    } else {
      this.clearContainers();
    }

    // если остался один компонент - то отключаем у него синюю рамку
    // иначе включаем
    if (this.view === ChartView.SINGLE) {
      this.componentsArray
        .filter((c) => c)
        .forEach((c) => {
          if ('canShowBorder' in c!.component.instance) {
            c!.component.instance.canShowBorder = false;
          }
        });
    } else {
      this.componentsArray
        .filter((c) => c)
        .forEach((c) => {
          if ('canShowBorder' in c!.component.instance) {
            c!.component.instance.canShowBorder = true;
          }
        });
    }

    switch (this.view) {
      case ChartView.DOUBLE_HOR:
        this.drawTwoHorizontally(drawNews);
        break;
      case ChartView.DOUBLE_VER:
        this.drawTwoVertically(drawNews);
        break;
      case ChartView.TRIPLE:
        this.drawThree(drawNews);
        break;
      case ChartView.FOUR:
        this.drawFour(drawNews);
        break;
      case ChartView.SINGLE:
        this.drawOne(drawNews);
        break;
    }
  }

  setContainers(
    first: ViewContainerRef,
    second: ViewContainerRef,
    third: ViewContainerRef,
    fourth: ViewContainerRef
  ): void {
    this.firstContainer = first;
    this.secondContainer = second;
    this.thirdContainer = third;
    this.fourthContainer = fourth;
  }

  // отрисовка

  private drawOne(drawNews = false): void {
    const filtered = this.componentsArray.filter((c) => c);
    const cssStyles = {
      height: '564px',
      width: '100%',
    };

    if (filtered.length === 1) {
      filtered[0]!.cssStyles = cssStyles;
      return;
    }

    const component = drawNews
      ? this.drawNews(this.firstContainer)
      : this.drawChart(this.firstContainer);

    if (!drawNews) {
      (component.instance as ChartComponent).isActive = true;
      (component.instance as ChartComponent).canShowBorder = false;
    }

    this.componentsArray[0] = {
      component,
      cssStyles,
    };
  }

  private drawTwoHorizontally(drawNews = false): void {
    const filtered = this.componentsArray.filter((c) => c);
    const cssStyles = {
      height: '320px',
      width: '100%',
    };

    if (filtered.length === 2) {
      filtered.forEach((c) => (c!.cssStyles = cssStyles));
      return;
    }

    const component = drawNews
      ? this.drawNews(this.secondContainer)
      : this.drawChart(this.secondContainer);

    this.componentsArray[1] = {
      component,
      cssStyles,
    };
    this.componentsArray[0]!.cssStyles = this.componentsArray[1].cssStyles;
  }

  private drawTwoVertically(drawNews = false): void {
    const filtered = this.componentsArray.filter((c) => c);
    const cssStyles = {
      height: '564px',
    };

    if (filtered.length === 2) {
      filtered.forEach((c) => (c!.cssStyles = cssStyles));
      return;
    }

    const component = drawNews
      ? this.drawNews(this.secondContainer)
      : this.drawChart(this.secondContainer);

    this.componentsArray[1] = {
      component,
      cssStyles,
    };
    this.componentsArray[0]!.cssStyles = this.componentsArray[1].cssStyles;
  }

  private drawThree(drawNews = false): void {
    const filtered = this.componentsArray.filter((c) => c);
    const containers = [
      this.firstContainer,
      this.secondContainer,
      this.thirdContainer,
      this.fourthContainer,
    ];
    const firstElementCss = {
      'grid-row': 1,
      height: '320px',
    };
    const secondElementCss = {
      'grid-row': 2,
      height: '320px',
    };
    const thirdElementCss = {
      'grid-row': '1 / span 2',
      height: '656px',
    };

    if (filtered.length === 3) {
      filtered[0]!.cssStyles = firstElementCss;
      filtered[1]!.cssStyles = secondElementCss;
      filtered[2]!.cssStyles = thirdElementCss;
      return;
    }

    // всегда есть хотя бы 1 элемент

    if (filtered.length === 1) {
      // ищем слот для второго элемента, если его нет
      const emptyCellIndex = this.componentsArray.findIndex((c) => !c);
      const component = this.drawChart(containers[emptyCellIndex]);
      this.componentsArray[emptyCellIndex] = {
        component,
      };
    }

    // ищем слот для третьего элемента, если его нет
    const emptyCellIndex = this.componentsArray.findIndex((c) => !c);

    const component = drawNews
      ? this.drawNews(containers[emptyCellIndex])
      : this.drawChart(containers[emptyCellIndex]);

    this.componentsArray[emptyCellIndex] = {
      component,
    };

    const newFiltered = this.componentsArray.filter((c) => c);
    newFiltered[0]!.cssStyles = firstElementCss;
    newFiltered[1]!.cssStyles = secondElementCss;
    newFiltered[2]!.cssStyles = thirdElementCss;
  }

  private drawFour(drawNews = false): void {
    const firstRowCss = {
      height: '320px',
      'grid-row': 1,
    };
    const secondRowCss = {
      height: '320px',
      'grid-row': 2,
    };

    if (!this.componentsArray[1]) {
      const component = this.drawChart(this.secondContainer);
      this.componentsArray[1] = {
        component,
      };
    }
    this.componentsArray[1].cssStyles = secondRowCss;

    if (!this.componentsArray[2]) {
      const component = this.drawNews(this.thirdContainer);
      this.componentsArray[2] = {
        component,
      };
    }
    this.componentsArray[2].cssStyles = firstRowCss;

    const component = drawNews
      ? this.drawNews(this.fourthContainer)
      : this.drawChart(this.fourthContainer);

    this.componentsArray[3] = {
      component,
      cssStyles: secondRowCss,
    };

    this.componentsArray[0]!.cssStyles = firstRowCss;
  }

  private clearContainers(): void {
    switch (this.view) {
      case ChartView.DOUBLE_HOR:
      case ChartView.DOUBLE_VER:
        this.fourthContainer.remove();
        this.thirdContainer.remove();
        this.componentsArray[3] = undefined;
        this.componentsArray[2] = undefined;
        break;
      case ChartView.TRIPLE:
        this.fourthContainer.remove();
        this.componentsArray[3] = undefined;
        break;
      case ChartView.SINGLE:
        this.fourthContainer.remove();
        this.thirdContainer.remove();
        this.secondContainer.remove();
        this.componentsArray[3] = undefined;
        this.componentsArray[2] = undefined;
        this.componentsArray[1] = undefined;
        break;
      case ChartView.FOUR:
        this.fourthContainer.remove();
        break;
    }
  }

  private clearContainer(): void {
    switch (this.indexToRemove) {
      case 0:
        this.firstContainer.remove();
        break;
      case 1:
        this.secondContainer.remove();
        break;
      case 2:
        this.thirdContainer.remove();
        break;
      case 3:
        this.fourthContainer.remove();
        break;
    }

    this.componentsArray[this.indexToRemove!] = undefined;
  }

  private drawChart(container: ViewContainerRef): ComponentRef<ChartComponent> {
    const componentFactory =
      this.resolver.resolveComponentFactory(ChartComponent);
    return container.createComponent(componentFactory);
  }

  private drawNews(container: ViewContainerRef): ComponentRef<NewsComponent> {
    const componentFactory =
      this.resolver.resolveComponentFactory(NewsComponent);
    return container.createComponent(componentFactory);
  }
}
