import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DescriptionService } from './description.service';
import { EChartTypes } from 'src/app/modules/chart/models/chart-types';

@Component({
  standalone: true,
  selector: 'app-chart-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  imports: [CommonModule],
})
export class ChartDescriptionComponent implements OnInit {
  constructor(public descriptionService: DescriptionService) {}

  public EChartTypes = EChartTypes;

  ngOnInit(): void {}
}
