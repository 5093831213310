import { Injectable } from '@angular/core';
import { NewsRepository } from './repository/news';
import { Subscription, catchError } from 'rxjs';
import { INews } from './models/news';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  public news: Array<INews> = [];
  public isLoading: boolean = false;
  protected prevSubscription: Subscription | null = null;

  constructor(
      protected readonly newsRepository: NewsRepository,
      protected readonly notifierService: NotifierService
  ) {}

  public fetchNewsList(): void {
    if (this.prevSubscription && !this.prevSubscription.closed) {
      this.prevSubscription.unsubscribe();
    }

    this.isLoading = true;
    this.prevSubscription = this.newsRepository
      .getNews()
      .pipe(
          catchError((error) => {
            this.isLoading = false;
            this.news = [];
            throw error;
          })
      )
      .subscribe((response) => {
        if (response.success) {
          this.news = response.data.items;
        } else if (response.error) {
          console.error(response.error);
          this.notifierService.notify('error', response.error);
        }
        this.isLoading = false;
      });
  }
}
