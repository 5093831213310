import { Component, OnInit } from '@angular/core';
import { SelectComponent } from '../ui/controls/select/select.component';
import { IOption } from 'src/app/shared/interfaces/chart-options.interface';
import {
  EChartTypes,
  chartTypeOptions,
  EChartIntervals,
  EChartPeriods,
} from 'src/app/modules/chart/models/chart-types';
import {
  EPayerTypes,
  payerTypeOptions,
} from 'src/app/modules/chart/models/payer-types';
import {
  EPositionTypes,
  individualPositionTypeOptions,
  legalPositionTypeOptions,
} from 'src/app/modules/chart/models/position-types';
import { getCurrentOption } from 'src/app/shared/utils/options';
import { CommonModule } from '@angular/common';
import { ChartFilters } from 'src/app/modules/chart/models/chart-filters';
import { FiltersService } from './filters.service';
import { cloneDeep } from 'lodash';
import { defaultState } from './default-state';
import { skip } from 'rxjs';
import { DescriptionService } from '../chart-description/description.service';

@Component({
  standalone: true,
  selector: 'app-chart-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  imports: [SelectComponent, CommonModule],
})
export class ChartFiltersComponent implements OnInit {
  constructor(
    private readonly filtersService: FiltersService,
    private descriptionService: DescriptionService
  ) {}

  public positionTypeOptions: IOption<EPositionTypes>[] = [];
  public readonly chartTypeOptions: IOption<EChartTypes>[] = chartTypeOptions;
  public readonly payerTypeOptions: IOption<EPayerTypes>[] = payerTypeOptions;
  public getCurrentOption = getCurrentOption;

  public currentState: ChartFilters;
  public showInterval = true;
  public intervalOptions: Array<IOption<EChartIntervals>> = [];
  public periodOptions: IOption<EChartPeriods>[] = [];

  ngOnInit(): void {
    this.currentState = cloneDeep(defaultState);
    this.updateIntervalOptions();
    this.updatePeriodOptions();
    this.setPositionTypeOptions();
    this.checkIntervalFilterState();

    this.filtersService.updateState$.pipe(skip(1)).subscribe((newState) => {
      this.currentState = cloneDeep(newState);
      this.updateIntervalOptions();
      this.updatePeriodOptions();
      this.setPositionTypeOptions();
      this.checkIntervalFilterState();
    });
  }

  updateIntervalOptions(): void {
    this.intervalOptions = this.filtersService.getIntervalOptions(this.currentState);
  }

  updatePeriodOptions(): void {
    this.periodOptions = this.filtersService.getPeriodOptions(this.currentState);
  }

  updateFiltersState<ChartFiltersKey extends keyof ChartFilters>(
    key: ChartFiltersKey,
    value: ChartFilters[ChartFiltersKey]
  ): void {
    this.currentState[key] = value;

    if (key === 'type') {
      this.setPositionTypeOptions();
      this.updateIntervalOptions();
      this.updatePeriodOptions();

      this.filtersService.updateIntervalValue(this.currentState);
      this.filtersService.updatePeriodValue(this.currentState);

      this.descriptionService.currentChartType$.next(value as EChartTypes);
    } else if (key === 'period') {
      this.updateIntervalOptions();
      this.filtersService.updateIntervalValue(this.currentState);
    }

    this.checkIntervalFilterState();

    this.filtersService.notifyActiveComponent(cloneDeep(this.currentState));
  }

  isShowPositions(): boolean {
    return [EChartTypes.INDIVIDUAL, EChartTypes.LEGAL].includes(
      this.currentState.type
    );
  }

  isShowPayer(): boolean {
    return [
      EChartTypes.INDIVIDUAL,
      EChartTypes.LEGAL,
      EChartTypes.RSI,
      EChartTypes.FUTURES,
    ].includes(this.currentState.type);
  }

  private setPositionTypeOptions(): void {
    this.positionTypeOptions =
      this.currentState.type === EChartTypes.INDIVIDUAL
        ? individualPositionTypeOptions
        : legalPositionTypeOptions;
  }

  private checkIntervalFilterState(): void {
    this.showInterval = this.currentState.type !== EChartTypes.FUTURES;
  }
}
