import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceFormatPipe } from 'src/app/pipes/price-format.pipe';
import { ChartPanelComponent } from './components/chart-panel/chart-panel.component';
import { AssetPickerComponent } from './components/asset-picker/asset-picker.component';
import { BasicUpdateComponent } from 'src/app/components/charts/basic-update/basic-update.component';
import { DateInputComponent } from 'src/app/components/ui/controls/date-input/date-input.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { LoaderDotsComponent } from 'src/app/components/ui/loader-dots/loader-dots.component';
import { ChartService } from './chart.service';
import { ValueSignPipe } from 'src/app/pipes/value-sign.pipe';
import {
  IconArrowDownComponent,
  IconArrowUpComponent,
  IconCloseComponent,
  IconFullscreenComponent,
  IconPlusComponent,
  IconSmallscreenComponent,
} from 'src/app/components/icons/icons.component';
import { RouterLink } from '@angular/router';
import { ChartComponent } from './components/chart/chart.component';
import { FavoritesModule } from '../favorites/favorites.module';
import { PopupComponent } from '../../components/ui/popup/popup.component';
import { SubscriptionModule } from '../subscription/subscription.module';
import { SearchBarComponent } from 'src/app/components/ui/controls/search-bar/search-bar.component';
import { ViewSelectComponent } from 'src/app/components/ui/controls/view-select/view-select.component';
import { NewsComponent } from 'src/app/components/features/news/news.component';
import { ChartFiltersComponent } from 'src/app/components/chart-filters/filters.component';
import { ChartDescriptionComponent } from 'src/app/components/chart-description/description.component';
import { SelectComponent } from 'src/app/components/ui/controls/select/select.component';
import { IconSelectComponent } from 'src/app/components/ui/controls/icon-select/icon-select.component';
import { DotAnimationComponent } from 'src/app/components/animations/dot.component';

@NgModule({
  providers: [ChartService],
  declarations: [ChartPanelComponent, AssetPickerComponent, ChartComponent],
  imports: [
    CommonModule,
    RouterLink,
    PriceFormatPipe,
    ViewSelectComponent,
    BasicUpdateComponent,
    DateInputComponent,
    LoaderDotsComponent,
    ValueSignPipe,
    FavoritesModule,
    NewsComponent,
    ChartFiltersComponent,
    ChartDescriptionComponent,
    SelectComponent,
    IconSelectComponent,
    DotAnimationComponent,

    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),

    PopupComponent,
    SubscriptionModule,
    SearchBarComponent,

    // ------------- Icons
    IconArrowDownComponent,
    IconArrowUpComponent,
    IconFullscreenComponent,
    IconSmallscreenComponent,
    IconPlusComponent,
    IconCloseComponent,
  ],
  exports: [ChartPanelComponent, AssetPickerComponent],
})
export class ChartModule {}
