/*
  todo
  удалить в конце разработки
*/
export enum EDateFilterIntervalType {
  DEFAULT = 'day',
  DAY = 'day',
  MINUTE = 'minute',
}

export interface IDateFilter {
  range: {
    from: Date;
    to: Date;
  };
  interval: number;
  type: EDateFilterIntervalType;
}
