import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, BASE_URL } from 'src/constants';
import { Observable } from 'rxjs';
import { EAssetTypes } from '../models/asset-types';
import { IGetAssetsListResponseDTO } from '../dto/assets-list';
import { IGetAssetsNamesResponseDTO } from '../dto/assets-names';
import { ISearchAssetsListResponseDTO } from "../dto/assets-search";

@Injectable({
  providedIn: 'root',
})
export class AssetsRepository {
  constructor(private readonly _http: HttpClient) {}

  public getAssets(
    search: string,
    type: EAssetTypes | null,
    isin: string = ''
  ): Observable<IGetAssetsListResponseDTO> {
    return this._http.get<IGetAssetsListResponseDTO>(`${BASE_URL}${API_URL}/assets`, {
      params: { search, type: type || '', isin },
      withCredentials: true,
    });
  }

  public searchAssets(search: string): Observable<ISearchAssetsListResponseDTO> {
    return this._http.get<ISearchAssetsListResponseDTO>(`${BASE_URL}${API_URL}/assets/search`, {
      params: { search },
      withCredentials: true,
    });
  }
}
