import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ChartService } from '../../chart.service';
import { getLoadingOptions } from '../../chart/chart-theme';
import { AssetsService } from 'src/app/modules/assets/assets.service';
import { SubscriptionService } from '../../../subscription/subscription.service';
import { FiltersService } from 'src/app/components/chart-filters/filters.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { EAssetTypes } from 'src/app/modules/assets/models/asset-types';
import { ChartFilters } from '../../models/chart-filters';
import { defaultState } from 'src/app/components/chart-filters/default-state';
import { cloneDeep } from 'lodash';
import { DescriptionService } from 'src/app/components/chart-description/description.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  providers: [ChartService, AssetsService],
})
export class ChartComponent implements OnInit, OnDestroy {
  @Input() public isActive = false;
  @Input() public canShowBorder = true;
  @Input() public filtersState?: ChartFilters;

  public loadingOptions: object = getLoadingOptions('#005055', 'Загрузка');

  private currentChartState = cloneDeep(this.filtersState || defaultState);
  private subscriptions: Subscription[] = [];

  constructor(
    public readonly chartService: ChartService,
    public readonly assetsService: AssetsService,
    public readonly subscriptionService: SubscriptionService,
    private readonly filtersService: FiltersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly descriptionService: DescriptionService
  ) {}

  ngOnInit(): void {
    this.filtersService.clearSubscriptions();
    this.filtersService.subscribe(this);
    if (this.isActive) {
      this.filtersService.updateState$.next(this.currentChartState);
      this.descriptionService.currentChartType$.next(
        this.currentChartState.type
      );
    }
    this.subscriptions.push(
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
        this.initData(params['isin']);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setIsActive(): void {
    this.filtersService.clearSubscriptions();
    this.filtersService.subscribe(this);
    this.filtersService.updateState$.next(this.currentChartState);
    this.descriptionService.currentChartType$.next(this.currentChartState.type);
  }

  hasData(): boolean {
    if (this.assetsService.isLoading || this.chartService.isLoading)
      return true;

    const series = Array.isArray(this.chartService.options.series)
      ? this.chartService.options.series
      : [this.chartService.options.series];

    for (let seriesItem of series) {
      if (seriesItem?.data && Array.isArray(seriesItem?.data) && seriesItem?.data.length > 0) {
        return true;
      }
    }

    return false;
  }

  hasAsset() {
    if (this.assetsService.isLoading || this.chartService.isLoading)
      return true;
    return !!this.chartService.currentAsset;
  }

  isChartAvailable() {
    if (this.assetsService.isLoading || this.chartService.isLoading)
      return true;
    return this.chartService.currentAsset?.available;
  }

  loadData(state: ChartFilters): void {
    let needFetch = false;

    if (this.currentChartState.interval !== state.interval) {
      this.currentChartState.interval = state.interval;
      needFetch = true;
    }

    if (this.currentChartState.period !== state.period) {
      this.currentChartState.period = state.period;
      needFetch = true;
    }

    if (this.currentChartState.type !== state.type) {
      this.currentChartState.type = state.type;
      needFetch = true;
    }

    if (this.currentChartState.payer !== state.payer) {
      this.currentChartState.payer = state.payer;
      needFetch = true;
    }

    if (needFetch) {
      this.chartService.updateDateFilter(this.currentChartState);
      this.chartService.fetchReports(this.currentChartState);
      this.chartService.updateTimer(this.currentChartState);
    }

    if (this.currentChartState.position !== state.position) {
      this.currentChartState.position = state.position;
      this.chartService.updateChart(this.currentChartState);
    }
  }

  private initData(isin: string | undefined): void {
    if (isin && !this.chartService.currentAsset) {
      this.chartService.fetchAssetByIsin(isin, this.currentChartState);
    } else {
      this.assetsService.resetFilters(EAssetTypes.STOCK);
    }
  }
}
