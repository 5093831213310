import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'icon-arrow-up',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      width="24"
      height="24"
      rx="12"
      transform="matrix(1 0 0 -1 0 24.0002)"
      fill="#14913C"
    />
    <path d="M12 6.50024L17.1962 15.5002H6.80385L12 6.50024Z" fill="white" />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconArrowUpComponent {}

@Component({
  standalone: true,
  selector: 'icon-arrow-down',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect width="24" height="24" rx="12" fill="#EC1C1C" />
    <path d="M12 17.5L17.1962 8.5H6.80385L12 17.5Z" fill="white" />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconArrowDownComponent {}

@Component({
  standalone: true,
  selector: 'icon-fullscreen',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21 12V3H12M12 21H3V12"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect x="9" y="9" width="6" height="6" rx="1" fill="white" />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconFullscreenComponent {}

@Component({
  standalone: true,
  selector: 'icon-smallscreen',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    data-slot="icon"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25"
    />
  </svg>`,
  styleUrls: ['./icons.component.scss'],
})
export class IconSmallscreenComponent {}

// виды разметки

@Component({
  standalone: true,
  selector: 'icon-single-frame',
  template: `<svg
    width="24"
    height="24"
    viewBox="-2 -2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 17V3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17Z"
      stroke="#1BCD54"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg> `,
  styleUrls: ['./icons.component.scss'],
})
export class IconSingleFrameComponent {}

@Component({
  standalone: true,
  selector: 'icon-double-horizontal-frame',
  template: `<svg
    width="24"
    height="24"
    viewBox="-2 -2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V10M1 10V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V10M1 10H19"
      stroke="#1BCD54"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>`,

  styleUrls: ['./icons.component.scss'],
})
export class IconDoubleHorizontalFrameComponent {}

@Component({
  standalone: true,
  selector: 'icon-double-vertical-frame',
  template: `<svg
    width="24"
    height="24"
    viewBox="-2 -2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1H10M10 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H10M10 19V1"
      stroke="#1BCD54"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg> `,

  styleUrls: ['./icons.component.scss'],
})
export class IconDoubleVerticalFrameComponent {}

@Component({
  standalone: true,
  selector: 'icon-triple-frame',
  template: `<svg
    width="24"
    height="24"
    viewBox="-2 -2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1H10M10 19H3C1.89543 19 1 18.1046 1 17V10M10 19V10M10 1H3C1.89543 1 1 1.89543 1 3V10M10 1V10M10 10H1"
      stroke="#1BCD54"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg> `,

  styleUrls: ['./icons.component.scss'],
})
export class IconTripleFrameComponent {}

@Component({
  standalone: true,
  selector: 'icon-four-frame',
  template: `<svg
    width="24"
    height="24"
    viewBox="-2 -2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10V17C1 18.1046 1.89543 19 3 19H10M1 10V3C1 1.89543 1.89543 1 3 1H10M1 10H19M19 10V3C19 1.89543 18.1046 1 17 1H10M19 10V17C19 18.1046 18.1046 19 17 19H10M10 1V19"
      stroke="#1BCD54"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg> `,

  styleUrls: ['./icons.component.scss'],
})
export class IconFourFrameComponent {}

@Component({
  standalone: true,
  selector: 'icon-plus',
  template: `<svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#1BCD54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 4.75C10.75 4.55109 10.671 4.36032 10.5303 4.21967C10.3897 4.07902 10.1989 4 10 4C9.80109 4 9.61032 4.07902 9.46967 4.21967C9.32902 4.36032 9.25 4.55109 9.25 4.75V9.25H4.75C4.55109 9.25 4.36032 9.32902 4.21967 9.46967C4.07902 9.61032 4 9.80109 4 10C4 10.1989 4.07902 10.3897 4.21967 10.5303C4.36032 10.671 4.55109 10.75 4.75 10.75H9.25V15.25C9.25 15.4489 9.32902 15.6397 9.46967 15.7803C9.61032 15.921 9.80109 16 10 16C10.1989 16 10.3897 15.921 10.5303 15.7803C10.671 15.6397 10.75 15.4489 10.75 15.25V10.75H15.25C15.4489 10.75 15.6397 10.671 15.7803 10.5303C15.921 10.3897 16 10.1989 16 10C16 9.80109 15.921 9.61032 15.7803 9.46967C15.6397 9.32902 15.4489 9.25 15.25 9.25H10.75V4.75Z"
      />
    </svg>
    <ng-content></ng-content>`,

  styleUrls: ['./icons.component.scss'],
})
export class IconPlusComponent {}

@Component({
  standalone: true,
  selector: 'icon-close',
  template: `<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2426 6.81807C14.3833 6.67742 14.4623 6.48665 14.4623 6.28774C14.4623 6.08883 14.3833 5.89806 14.2426 5.75741C14.102 5.61676 13.9112 5.53774 13.7123 5.53774C13.5134 5.53774 13.3226 5.61676 13.182 5.75741L10 8.93939L6.81802 5.75741C6.67737 5.61676 6.4866 5.53774 6.28769 5.53774C6.08878 5.53774 5.89801 5.61676 5.75736 5.75741C5.61671 5.89806 5.53769 6.08883 5.53769 6.28774C5.53769 6.48665 5.61671 6.67742 5.75736 6.81807L8.93934 10.0001L5.75736 13.182C5.61671 13.3227 5.53769 13.5134 5.53769 13.7124C5.53769 13.9113 5.61671 14.102 5.75736 14.2427C5.89801 14.3833 6.08878 14.4624 6.28769 14.4624C6.4866 14.4624 6.67737 14.3833 6.81802 14.2427L10 11.0607L13.182 14.2427C13.3226 14.3833 13.5134 14.4624 13.7123 14.4624C13.9112 14.4624 14.102 14.3833 14.2426 14.2427C14.3833 14.102 14.4623 13.9113 14.4623 13.7124C14.4623 13.5134 14.3833 13.3227 14.2426 13.182L11.0607 10.0001L14.2426 6.81807Z"
      fill="white"
    />
  </svg>`,

  styleUrls: ['./icons.component.scss'],
})
export class IconCloseComponent {}
