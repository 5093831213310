<div
  class="flex flex-wrap justify-end max-phone2:flex-col laptop:gap-4 max-laptop:gap-4"
>
  <app-select
    *ngIf="showInterval"
    class="laptop:min-w-[117px] max-laptop:min-w-[117px] max-laptop:basis-0 flex-grow max-laptop:basis-0"
    [options]="intervalOptions"
    [current]="getCurrentOption(intervalOptions, currentState.interval)"
    (valueChanged)="updateFiltersState('interval', $event.value)"
  />

  <app-select
    class="laptop:min-w-[173px] flex-grow max-laptop:basis-0"
    [options]="periodOptions"
    [current]="getCurrentOption(periodOptions, currentState.period)"
    (valueChanged)="updateFiltersState('period', $event.value)"
  />

  <app-select
    class="laptop:min-w-[237px] max-laptop:min-w-[237px] flex-grow max-laptop:basis-0"
    [options]="chartTypeOptions"
    [current]="getCurrentOption(chartTypeOptions, currentState.type)"
    (valueChanged)="updateFiltersState('type', $event.value)"
  />

  <app-select
    class="laptop:min-w-[237px] max-laptop:min-w-[237px] flex-grow max-laptop:basis-0"
    *ngIf="isShowPositions()"
    [options]="positionTypeOptions"
    [current]="getCurrentOption(positionTypeOptions, currentState.position)"
    (valueChanged)="updateFiltersState('position', $event.value)"
  />

  <app-select
    class="laptop:min-w-[134px] max-laptop:min-w-[134px] flex-grow max-laptop:basis-0"
    *ngIf="isShowPayer()"
    [options]="payerTypeOptions"
    [current]="getCurrentOption(payerTypeOptions, currentState.payer)"
    (valueChanged)="updateFiltersState('payer', $event.value)"
  />
</div>
