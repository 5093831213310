<div class="flex flex-col border border-gray3 bg-black1 rounded-[4px] h-full relative">
  <div
    *ngIf="newsService.isLoading"
    class="absolute w-full top-[56px] left-0 bottom-0 z-10 flex items-center justify-center bg-black1 bg-opacity-80"
  >
    <app-loader-dots />
  </div>

  <div
    class="flex shrink-0 justify-between items-center py-2 px-4 border-b-[1px] border-gray3 h-[56px]"
  >
    <h5>Лента новостей</h5>
  </div>
  <div class="flex flex-col p-4 gap-2 overflow-auto">
    <div
      class="flex flex-col border-radius border border-[transparent] p-4 gray1__opacity gap-2 news-item"
      *ngFor="let newsItem of newsService.news"
    >
      <h5>{{ newsItem.header }}</h5>
      <span class="text-gray4 text-[12px]">{{
        newsItem.publicationDate | date : 'dd.MM.yyyy'
      }}</span>
      <span class="text-[12px]">{{ newsItem.description }}</span>
    </div>
  </div>
</div>
