import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, BASE_URL } from 'src/constants';
import { Observable} from 'rxjs';
import { IGetNewsListResponseDTO } from '../dto/news-list';

@Injectable({
  providedIn: 'root',
})
export class NewsRepository {
  constructor(private readonly _http: HttpClient) {}

  public getNews(): Observable<IGetNewsListResponseDTO> {
    return this._http.get<IGetNewsListResponseDTO>(`${BASE_URL}${API_URL}/content/news`);
  }
}
