<div class="relative">
  <div (click)="setIsOptionsOpened(!isOptionsOpened)">
    <label
      class="flex px-2 ml-4 border border-radius border-greenPrimary h-[40px] w-[40px] greenPrimary__opacity !ml-0"
    >
      <icon-plus />
    </label>
  </div>
  <ul
    class="select__options"
    [ngClass]="{
      'select__options--hidden': !isOptionsOpened
    }"
  >
    <li
      class="select__option"
      *ngFor="let option of iconOptions"
      (click)="setOption(option)"
    >
      {{ option.label }}

      <div *ngFor="let label of option.lineLabels" class="select__label">
        <div
          class="select__line"
          [ngStyle]="{ 'background-color': label.color }"
        ></div>
        <span>{{ label.text }}</span>
      </div>

      <div *ngFor="let label of option.signalLabels" class="select__label">
        <div
          class="select__signal"
          [ngStyle]="{ 'background-color': label.color }"
        ></div>
        <span>{{ label.text }}</span>
      </div>
    </li>
  </ul>
</div>
