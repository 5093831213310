import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { IconPlusComponent } from 'src/app/components/icons/icons.component';
import { activesTypeOptions } from 'src/app/modules/chart/models/actives-filters';
import { IOption } from 'src/app/shared/interfaces/chart-options.interface';

@Component({
  standalone: true,
  selector: 'app-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.scss'],
  imports: [CommonModule, IconPlusComponent],
})
export class IconSelectComponent {
  public iconOptions = activesTypeOptions;
  public isOptionsOpened: boolean = false;
  public current: IOption<any>;

  @Output() valueChanged = new EventEmitter<IOption<any>>();

  setIsOptionsOpened(state: boolean) {
    this.isOptionsOpened = state;
  }

  setOption(option: IOption<any>) {
    this.valueChanged.emit(option);
    this.setIsOptionsOpened(false);
  }
}
