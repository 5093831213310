import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/modules/news/news.service';
import { IconCloseComponent } from '../../icons/icons.component';
import { CommonModule } from '@angular/common';
import { LoaderDotsComponent } from '../../ui/loader-dots/loader-dots.component';

@Component({
  standalone: true,
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  imports: [IconCloseComponent, CommonModule, LoaderDotsComponent],
})
export class NewsComponent implements OnInit {
  constructor(
    public readonly newsService: NewsService
  ) {}

  ngOnInit(): void {
    this.newsService.fetchNewsList();
  }
}
