<div
  class="chart border border-[2px]"
  [ngClass]="isActive && canShowBorder ? 'border-blue' : 'border-[transparent]'"
>
  <div class="chart__unavailable" *ngIf="hasAsset() && !isChartAvailable()">
    <svg
      class="h-[56px] max-small:h-[32px] max-phone2:h-[24px]"
      viewBox="0 0 289 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.2972 28.6428C39.2972 35.234 33.9532 40.5772 27.3611 40.5772C20.7689 40.5772 15.4249 35.234 15.4249 28.6428C15.4249 22.0516 20.7689 16.7084 27.3611 16.7084C33.9532 16.7084 39.2972 22.0516 39.2972 28.6428Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.197 1.2854H27.0588V5.65093V10.0165H26.197C16.5515 10.0165 8.73233 17.8345 8.73233 27.4786C8.73233 38.4085 17.5957 47.269 28.5256 47.269C38.1711 47.269 45.9903 39.451 45.9903 29.8069V28.93H50.3564H54.7226V29.8069C54.7226 44.273 42.9938 56.0001 28.5256 56.0001H8.73233H0V47.269V27.4786C0 13.0125 11.7288 1.2854 26.197 1.2854Z"
        fill="white"
      />
      <path
        d="M56.0079 20.3802C56.0079 23.6251 53.377 26.2556 50.1317 26.2556C46.8863 26.2556 44.2554 23.6251 44.2554 20.3802C44.2554 17.1353 46.8863 14.5048 50.1317 14.5048C53.377 14.5048 56.0079 17.1353 56.0079 20.3802Z"
        fill="white"
      />
      <path
        d="M35.6246 11.7508C38.87 11.7508 41.5009 9.1203 41.5009 5.8754C41.5009 2.63051 38.87 0 35.6246 0C32.3792 0 29.7484 2.63051 29.7484 5.8754C29.7484 9.1203 32.3792 11.7508 35.6246 11.7508Z"
        fill="white"
      />
      <path
        d="M50.1315 11.7508C53.3769 11.7508 56.0078 9.1203 56.0078 5.8754C56.0078 2.63051 53.3769 0 50.1315 0C46.8861 0 44.2552 2.63051 44.2552 5.8754C44.2552 9.1203 46.8861 11.7508 50.1315 11.7508Z"
        fill="white"
      />
      <path
        d="M151.724 39.1497V16.5936H154.639V39.1497H151.724Z"
        fill="white"
      />
      <path
        d="M172.913 16.0523C175.592 16.0523 177.698 16.8943 179.229 18.5785C180.79 20.2327 181.57 22.4732 181.57 25.3003V39.1497H178.655V25.3003C178.655 23.2852 178.125 21.7214 177.065 20.6086C176.034 19.4958 174.562 18.9394 172.647 18.9394C170.527 18.9394 168.804 19.6312 167.479 21.0146C166.154 22.368 165.491 24.4732 165.491 27.3303V39.1497H162.576V16.5936H165.491V20.1124C167.141 17.4056 169.614 16.0523 172.913 16.0523Z"
        fill="white"
      />
      <path
        d="M191.255 22.5033C191.255 23.5258 191.697 24.3379 192.58 24.9394C193.464 25.5409 194.539 26.007 195.805 26.3378C197.071 26.6687 198.337 27.0446 199.604 27.4656C200.87 27.8566 201.945 28.5333 202.828 29.4957C203.712 30.428 204.154 31.6611 204.154 33.1949C204.154 35.1197 203.403 36.6836 201.901 37.8866C200.428 39.0895 198.514 39.6911 196.158 39.6911C194.038 39.6911 192.227 39.2099 190.725 38.2475C189.252 37.2851 188.222 36.037 187.633 34.5032L190.107 33.0144C190.519 34.1874 191.255 35.1197 192.315 35.8114C193.405 36.4731 194.686 36.8039 196.158 36.8039C197.601 36.8039 198.809 36.5182 199.78 35.9467C200.752 35.3452 201.238 34.428 201.238 33.1949C201.238 32.1724 200.796 31.3603 199.913 30.7588C199.03 30.1573 197.955 29.6912 196.688 29.3604C195.422 29.0295 194.156 28.6686 192.889 28.2777C191.623 27.8566 190.548 27.1799 189.665 26.2476C188.781 25.2852 188.34 24.0371 188.34 22.5033C188.34 20.6687 189.046 19.1349 190.46 17.9019C191.903 16.6688 193.714 16.0523 195.893 16.0523C197.69 16.0523 199.265 16.4733 200.62 17.3154C201.974 18.1274 202.976 19.2402 203.624 20.6537L201.194 22.0973C200.34 19.9921 198.573 18.9394 195.893 18.9394C194.627 18.9394 193.537 19.2552 192.624 19.8868C191.711 20.4883 191.255 21.3605 191.255 22.5033Z"
        fill="white"
      />
      <path
        d="M210.552 39.1497V16.5936L213.467 16.5936V39.1497H210.552Z"
        fill="white"
      />
      <path
        d="M240.399 7.57117L243.314 7.57117V39.1497H240.399V34.7287C238.367 38.0369 235.392 39.6911 231.476 39.6911C228.325 39.6911 225.63 38.5482 223.392 36.2625C221.183 33.9768 220.079 31.1799 220.079 27.8717C220.079 24.5634 221.183 21.7665 223.392 19.4808C225.63 17.1951 228.325 16.0523 231.476 16.0523C235.392 16.0523 238.367 17.7064 240.399 21.0146V7.57117ZM225.512 34.2325C227.191 35.9467 229.252 36.8039 231.697 36.8039C234.141 36.8039 236.202 35.9467 237.881 34.2325C239.559 32.4881 240.399 30.3679 240.399 27.8717C240.399 25.3754 239.559 23.2702 237.881 21.5559C236.202 19.8116 234.141 18.9394 231.697 18.9394C229.252 18.9394 227.191 19.8116 225.512 21.5559C223.834 23.2702 222.994 25.3754 222.994 27.8717C222.994 30.3679 223.834 32.4881 225.512 34.2325Z"
        fill="white"
      />
      <path
        d="M261.441 16.0523C264.71 16.0523 267.346 17.2402 269.348 19.6161C271.38 21.962 272.396 24.7439 272.396 27.9619C272.396 28.2927 272.367 28.7589 272.308 29.3604L252.916 29.3604C253.21 31.646 254.153 33.4656 255.743 34.8189C257.363 36.1422 259.35 36.8039 261.706 36.8039C263.385 36.8039 264.828 36.458 266.035 35.7663C267.272 35.0445 268.2 34.1122 268.818 32.9693L271.38 34.5032C270.409 36.0971 269.083 37.3603 267.405 38.2926C265.726 39.2249 263.812 39.6911 261.662 39.6911C258.187 39.6911 255.36 38.5783 253.181 36.3527C251.002 34.1272 249.912 31.3002 249.912 27.8717C249.912 24.5033 250.987 21.6913 253.137 19.4357C255.286 17.1801 258.055 16.0523 261.441 16.0523ZM261.441 18.9394C259.144 18.9394 257.215 19.6462 255.655 21.0597C254.123 22.4431 253.21 24.2476 252.916 26.4732H269.393C269.098 24.0973 268.2 22.2477 266.698 20.9244C265.196 19.6011 263.444 18.9394 261.441 18.9394Z"
        fill="white"
      />
      <path
        d="M281.699 20.3379C283.054 17.6011 285.381 16.2327 288.679 16.2327V19.1199C286.676 19.1199 285.012 19.7214 283.687 20.9244C282.362 22.1274 281.699 24.0221 281.699 26.6085V39.1497H278.784V16.5936H281.699V20.3379Z"
        fill="white"
      />
      <path
        d="M152.967 11.5862C153.555 11.5862 154.056 11.391 154.467 11.0006C154.879 10.6103 155.085 10.1363 155.085 9.57866C155.085 9.02103 154.879 8.54704 154.467 8.15669C154.056 7.76635 153.555 7.57117 152.967 7.57117C152.379 7.57117 151.879 7.76635 151.467 8.15669C151.055 8.54704 150.849 9.02103 150.849 9.57866C150.849 10.1363 151.055 10.6103 151.467 11.0006C151.879 11.391 152.379 11.5862 152.967 11.5862Z"
        fill="white"
      />
      <path
        d="M211.919 11.5862C212.508 11.5862 213.008 11.391 213.42 11.0006C213.831 10.6103 214.037 10.1363 214.037 9.57866C214.037 9.02103 213.831 8.54704 213.42 8.15669C213.008 7.76635 212.508 7.57117 211.919 7.57117C211.331 7.57117 210.831 7.76635 210.419 8.15669C210.007 8.54704 209.801 9.02103 209.801 9.57866C209.801 10.1363 210.007 10.6103 210.419 11.0006C210.831 11.391 211.331 11.5862 211.919 11.5862Z"
        fill="white"
      />
      <path
        d="M91.7855 16.0336C94.2764 16.0336 96.2604 16.8699 97.7376 18.5427C99.2437 20.2155 99.9968 22.4558 99.9968 25.2636V39.0638H94.3923V25.6668C94.3923 24.3227 94.0737 23.2772 93.4365 22.5304C92.7993 21.7837 91.9014 21.4103 90.7428 21.4103C89.4684 21.4103 88.4692 21.8434 87.7451 22.7097C87.05 23.5759 86.7024 24.8305 86.7024 26.4734V39.0638H81.0979V25.6668C81.0979 24.3227 80.7793 23.2772 80.1421 22.5304C79.5049 21.7837 78.607 21.4103 77.4485 21.4103C76.203 21.4103 75.2038 21.8434 74.4507 22.7097C73.7266 23.5759 73.3646 24.8305 73.3646 26.4734V39.0638H67.7601V16.6609H73.3646V19.0356C74.6679 17.0342 76.6809 16.0336 79.4035 16.0336C82.0682 16.0336 84.0377 17.1089 85.3121 19.2596C86.7603 17.1089 88.9181 16.0336 91.7855 16.0336Z"
        fill="white"
      />
      <path
        d="M109.927 23.0233C109.927 23.6207 110.303 24.1136 111.056 24.5019C111.838 24.8603 112.78 25.1889 113.88 25.4876C114.981 25.7565 116.082 26.1149 117.182 26.563C118.283 26.9812 119.21 27.6981 119.963 28.7137C120.745 29.7293 121.136 30.9988 121.136 32.5222C121.136 34.8222 120.296 36.5995 118.616 37.8541C116.965 39.0788 114.894 39.6911 112.403 39.6911C107.943 39.6911 104.902 37.9138 103.28 34.3592L108.146 31.5364C108.783 33.478 110.202 34.4488 112.403 34.4488C114.402 34.4488 115.401 33.8066 115.401 32.5222C115.401 31.9247 115.01 31.4468 114.228 31.0884C113.475 30.7001 112.548 30.3565 111.447 30.0578C110.347 29.7591 109.246 29.3857 108.146 28.9377C107.045 28.4896 106.104 27.7877 105.322 26.8318C104.569 25.8461 104.192 24.6214 104.192 23.1577C104.192 20.9473 104.974 19.2148 106.538 17.9602C108.131 16.6758 110.101 16.0336 112.447 16.0336C114.213 16.0336 115.821 16.4518 117.269 17.2881C118.717 18.0946 119.861 19.2596 120.701 20.783L115.922 23.4714C115.227 21.948 114.069 21.1863 112.447 21.1863C111.723 21.1863 111.114 21.3505 110.622 21.6791C110.159 22.0077 109.927 22.4558 109.927 23.0233Z"
        fill="white"
      />
      <path
        d="M134.783 39.6911C131.51 39.6911 128.773 38.556 126.572 36.2859C124.4 34.0157 123.314 31.2079 123.314 27.8623C123.314 24.5168 124.4 21.709 126.572 19.4388C128.773 17.1687 131.51 16.0336 134.783 16.0336C136.898 16.0336 138.824 16.5563 140.561 17.6018C142.299 18.6472 143.617 20.0512 144.515 21.8135L139.693 24.7259C139.258 23.7999 138.592 23.0681 137.694 22.5304C136.825 21.9928 135.84 21.7239 134.74 21.7239C133.06 21.7239 131.67 22.3064 130.569 23.4714C129.468 24.6064 128.918 26.0701 128.918 27.8623C128.918 29.6247 129.468 31.0884 130.569 32.2533C131.67 33.3884 133.06 33.956 134.74 33.956C135.869 33.956 136.869 33.7021 137.738 33.1943C138.635 32.6566 139.302 31.9247 139.736 30.9988L144.602 33.8663C143.646 35.6287 142.299 37.0476 140.561 38.1229C138.824 39.1684 136.898 39.6911 134.783 39.6911Z"
        fill="white"
      />
    </svg>
    <div class="mt-8 text-center">
      Данный график недоступен для вас. Хотите получить доступ?
    </div>

    <button
      class="mt-8 whitespace-nowrap"
      (click)="subscriptionService.openTariffPopup()"
    >
      Присоединиться
    </button>
  </div>
  <div class="chart__empty" *ngIf="!hasAsset() || !hasData()">
    <h3>Нет данных</h3>
  </div>
  <div
    *ngIf="isChartAvailable() && hasData()"
    echarts
    [options]="chartService.options"
    [loadingOpts]="loadingOptions"
    [loading]="chartService.isLoading || assetsService.isLoading"
    class="chart__chart"
  ></div>
</div>

<app-popup
  [isOpened]="subscriptionService.isTariffPopupOpened"
  (closeModalEvent)="subscriptionService.closeTariffPopup()"
>
  <div class="light-ellipse">
    <div class="light-ellipse__circle top-0 left-0"></div>
  </div>
  <div
    class="mb-6 pt-6 pb-8 border-[1px] border-gray2 rounded-bl-none rounded-3xl spliced-block-gradient--double"
  >
    <h2 class="text-center leading-none">Тарифные планы</h2>
    <p class="mt-3 text-body-2 text-center">
      Выберите свой путь к успеху с нашими тарифными планами!
    </p>
  </div>
  <app-tariff-list />
  <div class="light-ellipse">
    <div class="light-ellipse__circle right-0 bottom-0"></div>
  </div>
</app-popup>

<app-asset-picker
  class="small:max-w-[80%] absolute z-[10] top-0 mt-4 ml-20"
  [avalibleAssets]="assetsService.avalibleAssets"
  [unavalibleAssets]="assetsService.unavalibleAssets"
/>
