import { ChartFilters } from 'src/app/modules/chart/models/chart-filters';
import { EChartIntervals, EChartPeriods, EChartTypes } from 'src/app/modules/chart/models/chart-types';
import { EPayerTypes } from 'src/app/modules/chart/models/payer-types';
import { EPositionTypes } from 'src/app/modules/chart/models/position-types';

const today = new Date();
const isWeekend = today.getDay() === 0 || today.getDay() === 6;

export const defaultState: ChartFilters = {
  interval: EChartIntervals.FIVEMINUTES,
  period: isWeekend ? EChartPeriods.WEEK : EChartPeriods.TODAY,
  type: EChartTypes.LEGAL,
  position: EPositionTypes.SHORT_LONG,
  payer: EPayerTypes.LEGAL,
  isOnline: true,
};
